var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-supir-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addSupirSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.supirLocal.id > 0 ? 'Edit Supir' : 'Add Supir')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama","label-for":"supir-name"}},[_c('validation-provider',{attrs:{"name":"Supir Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supir-name","state":errors.length > 0 ? false:null,"placeholder":"Nama Supir"},model:{value:(_vm.supirLocal.name),callback:function ($$v) {_vm.$set(_vm.supirLocal, "name", $$v)},expression:"supirLocal.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"supir-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supir-email","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.supirLocal.email),callback:function ($$v) {_vm.$set(_vm.supirLocal, "email", $$v)},expression:"supirLocal.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.supirLocal.id < 1),expression:"supirLocal.id < 1"}],attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"supir-password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supir-password","type":"password","state":errors.length > 0 ? false:null,"placeholder":"Password"},model:{value:(_vm.supirLocal.password),callback:function ($$v) {_vm.$set(_vm.supirLocal, "password", $$v)},expression:"supirLocal.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No Telepon","label-for":"supir-no-telepon"}},[_c('validation-provider',{attrs:{"name":"No Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supir-no-telepon","state":errors.length > 0 ? false:null,"placeholder":"No Telepon"},model:{value:(_vm.supirLocal.no_telp),callback:function ($$v) {_vm.$set(_vm.supirLocal, "no_telp", $$v)},expression:"supirLocal.no_telp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status Supir","label-for":"status-supir"}},[_c('validation-provider',{attrs:{"name":"Status Supir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status-supir","options":_vm.optionsSupir,"state":errors.length > 0 ? false:null,"placeholder":"Status Supir"},model:{value:(_vm.supirLocal.status_supir),callback:function ($$v) {_vm.$set(_vm.supirLocal, "status_supir", $$v)},expression:"supirLocal.status_supir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status-status"}},[_c('b-form-select',{attrs:{"id":"status-status","options":_vm.options,"placeholder":"Status"},model:{value:(_vm.supirLocal.status),callback:function ($$v) {_vm.$set(_vm.supirLocal, "status", $$v)},expression:"supirLocal.status"}})],1)],1),(_vm.supirLocal.id > 0 && _vm.supirLocal.foto)?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"preview-image"}},[_vm._v("Preview Foto Sebelumnya: ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-img',{staticClass:"mb-2",attrs:{"id":"preview-image","width":"200","src":_vm.supirLocal.foto,"fluid":"","alt":"Preview Image"}})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Foto Supir","label-for":"foto-supir"}},[_c('validation-provider',{attrs:{"name":"Foto Supir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"foto-supir","accept":"image/*","state":errors.length > 0 ? false:null,"placeholder":"Pilih File"},model:{value:(_vm.supirLocal.foto_supir),callback:function ($$v) {_vm.$set(_vm.supirLocal, "foto_supir", $$v)},expression:"supirLocal.foto_supir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }